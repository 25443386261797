<template>

    

    <div style="padding:20px;overflow:auto;">

        <div style="display:flex;flex-direction:row;margin-bottom:10px;">
            <v-card style="width:100%">
                             <v-container class="">
                                <v-row>
                                <v-col cols="12" md="2">
                                        <div style="width:100%;" >
                                            <v-text-field name="name" label="Nome Cliente" id="id" v-model="nomecliente"  ></v-text-field>
                                        </div>
                                </v-col>

                                <v-col cols="12" md="2">
                                        <div style="width:100%;" >
                                            <v-text-field name="name" label="Nome Etapa" id="id" v-model="nomeetapa"  ></v-text-field>
                                        </div>
                                </v-col>

                                <v-col cols="12" md="2">
                                        <div style="width:100%;margin-left:20px;" >
                                            <v-select color v-model="sistemaselecionado" :items="listadesistemas" label="Sistemas"  item-text="nome" return-object item-value="id"></v-select>
                                        </div>
                                </v-col>

                                <v-col cols="12" md="2">
                                        <div style="width:100%;margin-left:20px;" >
                                            <v-select color v-model="statuselecionado" :items="listadestatus" label="Status"  item-text="nome" return-object item-value="id"></v-select>
                                        </div>
                                </v-col>
                                
                                <v-col cols="12" md="2" style="padding-left:20px">
                                        <v-checkbox label="Ativas" v-model="somenteativas" ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="2" style="padding-left:20px">
                                        <v-checkbox label="Atrasadas" style="font-size:10px;" v-model="somenteatrasadas" ></v-checkbox>
                                </v-col>

                                <v-col cols="12" md="8">
                                        <!-- <div style="width:100%;" >
                                            <v-btn block elevation="" color="warning" style="margin-top:10px;width:100%" @click="limpar()" >Limpar</v-btn>
                                        </div> -->
                                </v-col>
                                <v-col cols="12" md="2">
                                        <div style="width:100%;" >
                                            <v-btn block elevation="" color="warning" style="margin-top:10px;width:100%" @click="limpar()" >Limpar</v-btn>
                                        </div>
                                </v-col>
                                <v-col cols="12" md="2">
                                        <div style="width:100%;margin-left:10px;" >
                                            <v-btn block elevation="" color="info" style="margin-top:10px;" @click="listarImplantacoes()" >Buscar</v-btn>
                                        </div>
                                </v-col>
                                </v-row>
                            </v-container>
            </v-card>


        </div>
        
  

        <table style="background-color:white;width:100%;border:1px solid black;">
            <tr>
                <td class="celulatablenome " style="" >Cliente</td>
                <td class="celulatable" style="" v-for="sistema in listadesistemasTabela" :key="sistema.id" >{{sistema.nome}}</td>  
            </tr>

            <tr style="padding:0px;margin:0px;" v-for="cliente in listadeimplantacoesporcliente" :key="cliente.id">
                <td class="celulatablenome" style="" >
                    <a @click="setModalMovimentos(cliente)" >{{cliente.nome}}</a></td>
                <td :class="`celulatable ${corStatus(cliente.sistemas,sistema)}`" style="border:1px solid black;"  v-for="sistema in listadesistemasTabela" :key="sistema.id" >
                    
                    <div style="font-size:12px;font-weight:bold;" v-if="verificaSeContem(cliente.sistemas,sistema)">
                        {{sistema.nome}}
                    </div>
                    <div v-if="verificaSeContem(cliente.sistemas,sistema)">
                        {{etapaAtualSistema(cliente.sistemas,sistema)}}
                    </div>
                    <div v-if="verificaSeContem(cliente.sistemas,sistema)">
                        Est. Etapa: {{retornaImplantacao(cliente.sistemas,sistema).dataconclusaoestimadaetapa | formatarData }}
                    </div>
                    <div v-if="verificaSeContem(cliente.sistemas,sistema)">
                        Est. Geral: {{retornaImplantacao(cliente.sistemas,sistema).dataconclusaoestimadatotal | formatarData}}
                    </div>    
                </td>  
            </tr>
        </table>

        <v-dialog v-model="exibirModalMovimento" width="80vw" style="max-height:90vh;height:90vw;"  >

                    <div style="display:flex;flex-direction:row;width:100%;max-height:80vh;height:80vw;">
                    
                
                            <div style="background-color:white;width:40%;display:flex;flex-direction:column;border-right:1px solid #b1b1b1;max-height:80vh;height:80vw;">

                                <div>
                                    <v-container class="pa-8" style="padding-top:1px!important;">
                                        <v-row>

                                            <v-col cols="12" md="12">Novo Movimento
                                                    <v-container class="">
                                                    <v-row>
                                                        <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-textarea height="50" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="movimento.descricao" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                        </v-col>
                                                    </v-row>
                                                    <v-row style="margin-top:20px;">
                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-text-field type="number" filled label="Horas" v-model="movimento.tempo_gasto" name="texto" hide-details ></v-text-field>
                                                        </v-col>
                                                        <v-col  cols="12" md="8" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-btn color="info" @click="registrarMovimento(3)"  hide-details>Movimentar</v-btn >
                                                        </v-col>
                                                    </v-row>
                                                  
                                                   
                                                    </v-container>
                                                        
                                            </v-col>
                                            
                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                
                            </div>

                            <div style="background-color:#efefef;width:60%;display:flex;flex-direction:column;padding:10px;max-height:80vh;height:80vw;overflow:auto;padding-bottom:20px!important;">

                                <v-container class="pa-2" style="max-height:80vh;padding-bottom:20px!important;">
                                    <v-card class="" style="margin-bottom:10px;" elevation="" max-width="" v-for="item in movimentos" :key="item.id" :dot-color="item.color" >
                                        <v-card-title style="font-size:16px;">{{item.desctipomovimento}} - {{item.tiporegistro}}</v-card-title>
                                        <v-card-text style="font-size:16px;">
                                            <div><b>Descrição:</b> {{item.descricao}}</div>
                                            <div><b>Horas Gastas:</b> {{item.tempo_gasto}}</div>
                                        </v-card-text>
                                        <v-card-text style="display:flex;flex-direction:column;justify-content:flex-end;align-items:flex-end;font-weight:600;color:#817373;">
                                            <div>{{item.datamovimento}} as {{item.horamovimento}}</div> 
                                            <div>{{item.nomeusuario}}</div> 
                                        </v-card-text>
                                    
                                    </v-card>
                                </v-container>

                            </div>

                    </div>

            </v-dialog>
        
        


    </div>

    
</template>

<script>


import sistemas from '@/services/sistema.service.js'
import implantacao from '@/services/implantacao.service.js'
import AtividadeServico from '@/services/atividade.service.js'


import dayjs from 'dayjs'

export default {
    name:'QUADRO IMPLANTAÇÃO',
    components: {
        
    },
    data(){
        return{
            movimento:{idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null},
            somenteativas:true,
            somenteatrasadas:false,
            sistema:{},
            listadesistemas:[],
            listadesistemasTabela:[],
            implantacao:{},
            listadeimplantacoesporcliente:[],
            nomecliente:null,
            nomeetapa:null,
            sistemaselecionado:{id:0,nome:'TODOS'},
            statuselecionado:{id:1,nome:'TODOS'},
            listadestatus:[
                {id:1,nome:'TODOS'},
                {id:2,nome:'CONCLUIDOS'},
                {id:3,nome:'PENDENTES'},
            ],
            exibirModalMovimento:false,
            movimentos:[],
            clienteselecionado:{},
        }
    },
    watch: {
        
    },
    methods:{
            async registrarMovimento(tipo){

                if(this.movimento.descricao){

                    this.movimento.idusuario = localStorage.getItem('idusuario');
                    this.movimento.tipomovimento = tipo;
                    this.movimento.idcliente = this.clienteselecionado.id
                    this.movimento.datahoramovimento = dayjs().format()

                    await AtividadeServico.registrarMovimentoPorCliente(this.movimento).then(async () => { 
                        this.movimento = {idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null}
                        this.buscarMovimentosPorCliente({id:this.clienteselecionado.id})
                    }).catch(error=>{
                        alert('Não foi Possível Registrar!6')
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })
                }else{
                    alert("Titulo Obrigatório!")
                }

            },
            async buscarMovimentosPorCliente (cliente){
              

                    await AtividadeServico.buscarMovimentoPorCliente({idcliente:cliente.id}).then(async (resp) => { 
                        let movimentos = resp.data
                        movimentos = movimentos.sort(function (a, b) {
                            if (a.datahoramovimento > b.datahoramovimento) {
                                return -1;
                            }
                            if (a.datahoramovimento < b.datahoramovimento) {
                                return 1;
                            }
                            return 0;
                        })
                        this.movimentos = movimentos

                    }).catch(error=>{
                        alert('Não foi Possível Registrar!4')
                        console.log(error)
                    })

            },
            setModalMovimentos(item){
 
                this.exibirModalMovimento = !this.exibirModalMovimento
                if(this.exibirModalMovimento){
                    this.clienteselecionado = item
                    this.buscarMovimentosPorCliente(item)
                }
            },
            limpar(){
                this.statuselecionado= {id:1,nome:'TODOS'}
                this.sistemaselecionado={id:0,nome:'TODOS'}
                this.nomecliente= null
                this.nomeetapa= null
                this.somenteativas =true
                this.somenteatrasadas =false
            },
            etapaAtualSistema(lista, objeto){
                let objetoEncontrado = lista.find(x =>{
                    return x.idsistema == objeto.id
                })

                if(!objetoEncontrado){
                    return null
                }else{
                    if(objetoEncontrado.concluida){
                        return 'Concluida'
                    }else{
                        return objetoEncontrado.Etapa
                    }
                }
            },
            corStatus(lista, objeto){

                let objetoEncontrado = lista.find(x =>{
                    return x.idsistema == objeto.id
                })

                if(!objetoEncontrado){
                    // return 'fundoCinza'
                    return 'fundoCinza2'
                }else{
                    console.log('objetoEncontrado ',JSON.stringify(objetoEncontrado,null,2))
                    if(
                        (objetoEncontrado.dataconclusaoestimadatotal && parseInt(dayjs(objetoEncontrado.dataconclusaoestimadatotal).format('YYYYMMDD')) < parseInt(dayjs().format('YYYYMMDD')) )
                        || ( objetoEncontrado.dataconclusaoestimadaetapa && parseInt(dayjs(objetoEncontrado.dataconclusaoestimadaetapa).format('YYYYMMDD') )< parseInt(dayjs().format('YYYYMMDD')) )
                     ){
                        // alert( parseInt(dayjs(objetoEncontrado.dataconclusaoestimadaetapa).format('YYYYMMDD')) +' -- '+ parseInt(dayjs().format('YYYYMMDD')))
                        // alert( parseInt(dayjs(objetoEncontrado.dataconclusaoestimadaetapa).format('YYYYMMDD') )< parseInt(dayjs().format('YYYYMMDD'))  )
                            //  return 'fundoVermelho'
                            if(objetoEncontrado.concluida ){
                                return 'fundoVerde'
                            }else{
                                return 'fundoVermelho'
                            }
                    }else{
                        // return 'fundoVerde'
                        // if(objetoEncontrado.Etapa && objetoEncontrado.ultimaEtapa && objetoEncontrado.Etapa.toUpperCase() == objetoEncontrado.ultimaEtapa.toUpperCase()){
                        if(objetoEncontrado.concluida ){
                            return 'fundoVerde'
                        }else{
                            return 'fundoAmarelo'
                        }

                    }

                }
            },
            verificaSeContem(lista, objeto){
                let existe = lista.find(x =>{
                    return x.idsistema == objeto.id
                })

                return existe

            },
            retornaImplantacao(lista, objeto){

                let implantacao = lista.find(x =>{
                    return x.idsistema == objeto.id
                })

                return implantacao

            },
            async listarSistemas(){
                // this.listadesistemas = await sistemas.buscar().then(res => res.data)
                let lista = [{id:0,nome:'Todos'}]
                let listadesis = await sistemas.buscar().then(res => res.data)
                this.listadesistemasTabela = listadesis
                let listafinal = lista.concat(listadesis)
                this.listadesistemas = listafinal

            },
            async listarImplantacoes(){
                this.listadeimplantacoesporcliente = await implantacao.buscarImplantacoesQuadro({nomecliente:this.nomecliente,nomeetapa:this.nomeetapa,statuselecionado:this.statuselecionado.id
                                                                                                    ,idsistema:this.sistemaselecionado.id
                                                                                                    ,somenteativas:this.somenteativas,somenteatrasadas:this.somenteatrasadas}).then(res => res.data)
            },
    },

    
    async beforeMount(){
        await this.listarSistemas()
        await this.listarImplantacoes()
    }
}
</script>

<style>

.topo{
    font-size: 16px;
    font-weight: bold;
    min-width:15%;
    max-width:15%;
}

.fundoCinza{
    background-color: #f5f5f5;
}

.fundoVerde{
    background-color: green;
    color:white;
    font-weight: bold;
}

.fundoVermelho{
    background-color: rgb(128, 0, 0);
    color:white;
    font-weight: bold;
}

.fundoAmarelo{
    background-color: #efef81;
    /* background-color: #fcfcc6; */
    padding:10px;
    /* font-weight: bold; */
}

.nomeCliente{
    font-weight: bold;
}

.quadro{
    min-width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    overflow: auto;
     /* max-width: 800px; */
}

.linha{
    display: flex;
    flex-direction: row;  
}

.coluna{
    /* width: 200px;
    max-width: 200px; */
    /* background-scolor: gray; */
}

.celula{
    min-width:15%;
    max-width:15%;
    border:solid 1px black;
    height: 80px;
    display: flex;
    align-items: center;
    /* padding-left: 10px; */
    /* background-color: red; */
}

.celulatable{
    min-width:15%;
    max-width:15%;
    border:solid 1px black;
    height: 80px;
    padding:10px;
    margin:0px;
    /* display: flex;
    align-items: center; */
    /* padding-left: 10px; */
    /* background-color: red; */
}

.celulatablenome{

    max-width:100px;
    border:solid 1px black;
    height: 80px;
    padding:10px;
    margin:0px;
    /* display: flex;
    align-items: center; */
    /* padding-left: 10px; */
    /* background-color: red; */
}

</style>